<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div v-for="result in resultData" :key="result.id">
        <div v-if="result.texts" v-html="result.texts"></div>
        <img v-else :src="`${OSS_URL}${result.xpicture}`" alt="" />
      </div>
    </div>
    <menu-pop :activeItem="7"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import menuPop from "@/components/menuPop";
import back from "@/components/back";
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PARTY_ORGANIZATION_OVERVIEW",
  props: {
    appid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icon: redIcon,
      resultData: null,
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
    } else {
      this.icon = blueIcon;
    }
    this.getPartyOrganizationOverview();
    // 保存路径
    this.setRouterList([{ name: '党组织概况', path: this.$route.fullPath }]);
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
      } else {
        this.icon = blueIcon;
      }
    },
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    getPartyOrganizationOverview() {
      this.POST(this.API_MODULE.SEARCH_PARTY_ORGANIZATION_OVERVIEW_BY_USER)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.resultData = res.data;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
  components: {
    navRouter,
    menuPop,
    back,
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";
.box {
  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0 0.6rem;
    box-sizing: border-box;
    @include background_color("background-medium");

    // 覆盖app.vue的样式
    text-align: left;
    img {
      width: 24.42rem;
      height: 11.3rem;
    }
  }
}
</style>
