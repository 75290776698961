<template>
  <component :is="currentAppComponent" :appid="appid"></component>
</template>

<script>
import PARTY_BUILDING_KNOWLEDGE from "@/views/dangjianLore/index.vue"; // 党建知识
import THE_CURRENT_POLITICS_NEWS from "@/views/news/index.vue"; // 时政要闻
import PARTY_BUILDING_ELEGANT_DEMEANOUR from "@/views/photos/index.vue"; // 党建风采
import WISDOM_CLASSROOM from "@/views/classroom/index.vue"; // 智慧课堂
// import WISDOM_INTERACTION from "@/views/interactive/index.vue" // 智慧互动 
import RED_PANORAMIC from "@/views/panoramic/index.vue"; // 红色全景
import PARTY_ORGANIZATION_OVERVIEW from "@/views/overview/index.vue"; // 党组织概况
import PARTY_LECTURE_JOURNAL from "@/views/dangHistory/index.vue"; // 党史馆
import RED_BASE from "@/views/base/index.vue"; // 红色基地
import PARTY_BUILDING_BIG_DATA from "@/views/bigData/index.vue"; // 大数据
import VOW_WALL from "@/views/promise/index.vue"; // 宣誓墙
export default {
  name: "apps",
  data() {
    return {
      appid: this.$route.params.id,
      currentApp: this.$route.params.code,
    };
  },
  computed: {
    currentAppComponent() {
      return this.currentApp;
    },
  },
  components: {
    PARTY_BUILDING_KNOWLEDGE,
    THE_CURRENT_POLITICS_NEWS,
    PARTY_BUILDING_ELEGANT_DEMEANOUR,
    WISDOM_CLASSROOM,
    RED_PANORAMIC,
    PARTY_LECTURE_JOURNAL,
    RED_BASE,
    VOW_WALL,
    PARTY_BUILDING_BIG_DATA,
    PARTY_ORGANIZATION_OVERVIEW,
    // WISDOM_INTERACTION
  },

};
</script>

<style lang="scss" scoped></style>
