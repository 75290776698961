<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" style="width: 0.16rem; height: 0.16rem" />
    </nav-router>
    <div class="content">
      <div class="floor-item" v-for="item in floorList" :key="item.id">
        <div class="floor-item-content">
          <p class="floor-item-name">
            <span>{{ item.name }}</span>
          </p>
          <div style="overflow-y: scroll">
            <!--                    父元素的宽度 = 单个宽度260 * 子元素的个数(length)  -->
            <div class="book-list" :style="{ width: 98 * item.books.length + 'px' }">
              <div class="floor-item-icon" v-for="book in item.books" :key="book.id" @click="handleClick(book)">
                <img :src="`${OSS_URL}${book.picture}`" alt="" />
                <p class="book-name">{{ book.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="floor-item-shelf">
          <img src="@/assets/images/dangHistory/shelf.png" alt="" />
        </div>
      </div>
    </div>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { openUrl } from '@/utils/windowOpen'
const blueBg = require('@/assets/images/index/bg-blue.png')
const redBg = require('@/assets/images/index/bg.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PARTY_LECTURE_JOURNAL',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      floorList: [],

      books: {}
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()
    // 保存路径
    this.setRouterList([{ name: '党史馆', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  },
  methods: {
    handleClick(book){

    },
    ...mapMutations('skin', ['setRouterList']),
    handleClick(item) {
      if (item.externalLinksUrl) {
        openUrl(item.externalLinksUrl)
      } else {
        this.$router.push({ path: '/layout/read', query: { id: item.id } })
      }
    },
    async getData() {
      const res = await this.GET(API_MODULE.SEARCH_CATEGORY_OR_CONTENT, {
        appId: this.appid
      })

      const categorys = res.data.model
      for (const key in categorys) {
        const floor = {}
        floor.id = categorys[key].id
        floor.name = categorys[key].name

        const courseResult = await this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [categorys[key].id]
        })
        if (courseResult.code == 0) {
          floor.books = courseResult.data.model
          this.floorList.push(floor)
        } else {
          // this.$message({ message: courseResult.msg, type: 'error' })
        }
      }
    }
  },
  components: {
    navRouter,
    back
  }
}
</script>
<style scoped lang="scss">
.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    padding-top: 0.8rem;
    padding-left: 0.6rem;
    padding-right: 0.7rem;

    .floor-item {
      margin-bottom: 0.8rem;

      &-content {
        display: flex;
        align-items: center;
        overflow: hidden;

        .book-list {
          /*width: 2262px;*/
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          overflow-x: scroll;
          overflow-y: hidden;
          flex-shrink: 0;
        }

        .floor-item-name {
          font-size: 0.72rem;
          font-weight: 400;
          color: #ffed0f;
          text-shadow: 0.08rem 0.1rem 0.02rem #b40000;
          opacity: 1;
          margin-left: 0.86rem;
          margin-right: 0.8rem;
          flex-shrink: 0;
        }

        .floor-item-icon {
          width: 1.8rem;
          height: 2.2rem;
          margin-right: 0.8rem;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .book-name {
            position: absolute;
            left: 0.1rem;
            bottom: 0.2rem;
            height: 0.5rem;
            line-height: 0.5rem;
            width: 1.6rem;
            background: #580000;
            color: #ffffff;
            font-size: 0.2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      &-shelf {
        width: 24.4rem;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
