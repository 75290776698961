<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="header">
        <div class="increase container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">党员增长</span>
          </div>
          <div class="context" id="increase-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="china container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">党员足迹图</span>
          </div>
          <div id="china-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div>
          <div class="dangke container">
            <div class="blcok-title">
              <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
              <span class="block-name">微党课</span>
            </div>
            <div id="dangke-map"></div>
            <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
            <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
            <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
            <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
          </div>
          <div class="sanhui-yike container">
            <div class="blcok-title">
              <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
              <span class="block-name">三会一课</span>
            </div>
            <div id="sanhui-yike-map"></div>
            <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
            <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
            <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
            <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
          </div>
        </div>
        <div class="integral container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">党员积分</span>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>积分</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="integral in integralList" :key="integral.id">
                  <td>{{ integral.fields1 }}</td>
                  <td>{{ integral.fields2 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="dangjian container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">党建概况</span>
          </div>
          <div class="list">
            <div class="item" v-for="(item, index) in overviewList" :key="index">
              <p class="item-number">{{ item.quantity }}</p>
              <p class="item-name">{{ item.name }}</p>
            </div>
          </div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
      </div>
      <div class="footer">
        <div class="gender container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">性别情况</span>
          </div>
          <div class="context" id="gender-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="age container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">年龄分布</span>
          </div>
          <div id="age-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="nationality container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">民族分布</span>
          </div>
          <div class="context" id="nationality-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="education container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">学历分布</span>
          </div>
          <div class="context" id="education-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="time container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">入党时间</span>
          </div>
          <div id="time-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
        <div class="profession container">
          <div class="blcok-title">
            <!-- <img src="@/assets/images/bigData/menu.png" alt="" /> -->
            <span class="block-name">职业分布</span>
          </div>
          <div id="profession-map"></div>
          <img src="@/assets/images/bigData/left-top.png" alt="" class="left-top-icon" />
          <img src="@/assets/images/bigData/left-bottom.png" alt="" class="left-bottom-icon" />
          <img src="@/assets/images/bigData/right-top.png" alt="" class="right-top-icon" />
          <img src="@/assets/images/bigData/right-bottom.png" alt="" class="right-bottom-icon" />
        </div>
      </div>
    </div>
    <menu-pop :activeItem="6"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from '@/components/navRouter'
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import echarts from 'echarts'
import china from 'echarts/map/json/china.json'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PARTY_BUILDING_BIG_DATA',
  props: {
    appid: {
      type: String,
      required: true
    }
  },
  components: {
    navRouter,
    menuPop,
    back
  },
  data() {
    return {
      icon: redIcon,
      // 党员增长
      increaseX: [],
      increase: [],
      // 党员分布
      chinaData: [],
      maxPepole: undefined,
      // 入党时间
      timeX: [],
      time: [],

      // 性别分布
      sexData: [],
      // 年龄分布
      ageX: [],
      age: [],
      // 民族分布
      nationalityX: [],
      nationality: [],
      // 学历分布
      educationX: [],
      education: [],

      // 职业分布
      professionMax: [],
      professionValue: [],

      // 微党课
      dangKeX: [],
      dangKe: [],

      // 三会一课
      SHYKX: [],
      SHYK: [],

      // 党员积分
      integralList: [],
      overviewList: []
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
    } else {
      this.icon = blueIcon
    }
    this.init()
    // 保存路径
    this.setRouterList([
      {
        name: '党建大数据',
        path: this.$route.fullPath
      }
    ])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    /**
     * 生成党员增长图
     * @params
     * @xAxis ->data 年份数据对应x轴
     * @series ->data 年份的对应数据 对应 y轴
     */
    initIncrease() {
      let dom = document.getElementById('increase-map')
      let charts = echarts.init(dom)
      const option = {
        grid: {
          left: '15%'
        },
        lineStyle: {
          color: '#ffffff',
          width: 1
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.increaseX,
          axisLabel: {
            fontSize: 12,
            color: '#ffffff'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#ffffff'
          }
        },
        series: [
          {
            data: this.increase,
            type: 'line',
            smooth: true,
            areaStyle: {
              color: '#CCA766'
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c}(人)'
            }
            // itemStyle: { normal: { label: { show: true } } }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 生成中国地图
     * params
     * @resultData  输入的各区域数值
     * @max 拖动条最大显示值
     * 拿到后台参数后自行覆盖
     * */
    initChina() {
      let dom = document.getElementById('china-map')
      let charts = echarts.init(dom)

      let seriesData = []
      echarts.registerMap('china', china)
      this.chinaData.forEach((item) => {
        seriesData.push({
          name: item.city,
          value: item.pepole
        })
      })
      let option = {
        // backgroundColor: "#F3F3F3", //地图背景颜色
        //以下是地图标题，我在此处设空，需要的可以自己加上
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        //以下是地图右侧“还原”“下载”工具框
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            restore: {},
            saveAsImage: {}
          }
        },
        visualMap: {
          //visualMap 是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
          show: true, //是否显示组件
          orient: 'horizontal',
          max: this.maxPepole, //指定 visualMapContinuous 组件的允许的最大值 此值需要排序后台拿到的值 拿到所有数据里最大值
          left: '5%',
          top: 'bottom',
          bottom: '1%',
          // text: ["Low", "High"], //两端的文本，如 ['High', 'Low']
          calculable: true, //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）。
          inRange: {
            //定义 在选中范围中 的视觉元素。
            color: ['#f8d7d7', '#E03838', '#C92727', '#B40000', '#EE162D', '#EE0012', '#950A0A'] //图元的颜色。
          },
          textStyle: {
            //设置文字颜色（Demo左下组件）950A0A
            color: '#eee',
            fontSize: 8
          }
        },
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: false
            },
            // 不需要显示地名可直接删除normal此项
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: '#ffffff',
                fontSize: 8
              }
            }
          },
          zoom: 1.2,
          roam: false,
          itemStyle: {
            normal: {
              areaColor: '#E32626', //地图颜色
              borderColor: '#fff' //地图边线颜色
            },
            emphasis: {
              areaColor: '#B7DFED' //鼠标移入颜色
            }
          }
        },
        series: [
          {
            name: '',
            type: 'map',
            mapType: 'china',
            geoIndex: 0, //理解就是多个组件都能用option里配置的geo地图，类似于坐标轴里的yAxisIndex:numder,也就是说你可以配置多个geo，也可以多个组件共用geo
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true
              }
            },
            // data: this.seriesData
            data: seriesData
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },

    /**
     * 微党课
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initDangke() {
      let dom = document.getElementById('dangke-map')
      let charts = echarts.init(dom)
      const option = {
        grid: {
          top: '20%',
          left: '20%',
          bottom: '20%'
        },
        lineStyle: {
          color: '#ffffff',
          width: 1
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: this.dangKeX,
          axisLabel: {
            fontSize: 12,
            color: '#ffffff',
            interval: 0,
            rotate: '45'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#ffffff'
          }
        },
        series: [
          {
            data: this.dangKe,
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              color: '#ffffff',
              formatter: '{c}(人)'
            }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 三会一课
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initSanhuiYike() {
      let dom = document.getElementById('sanhui-yike-map')
      let charts = echarts.init(dom)
      const option = {
        grid: {
          top: '20%',
          left: '20%',
          bottom: '20%'
        },
        lineStyle: {
          color: '#ffffff',
          width: 1
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: this.SHYKX,
          axisLabel: {
            fontSize: 12,
            color: '#ffffff',
            interval: 0,
            rotate: '45'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#ffffff'
          }
        },
        series: [
          {
            data: this.SHYK,
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              color: '#ffffff',
              formatter: '{c}(人)'
            }
            // itemStyle: {
            //   normal: {
            //     color: '#F89191'
            //   }
            // }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 性别情况
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initGenderMap() {
      let dom = document.getElementById('gender-map')
      let charts = echarts.init(dom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}人 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: '70%',
          bottom: '10%',
          data: ['男', '女'],
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        color: ['#e03838', '#ffee0b'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '65%'],
            center: ['40%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.sexData
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 年龄分布
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initAge() {
      let dom = document.getElementById('age-map')
      let charts = echarts.init(dom)
      const option = {
        grid: {
          top: '20%',
          left: '20%',
          bottom: '20%'
        },
        lineStyle: {
          color: '#ffffff',
          width: 1
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: this.ageX,
          axisLabel: {
            fontSize: 12,
            color: '#ffffff',
            interval: 0,
            rotate: '45'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            interval: 0,
            fontSize: 12,
            color: '#ffffff'
          }
        },
        series: [
          {
            data: this.age,
            type: 'bar',
            // barWidth: 30,
            // itemStyle: {
            //   normal: {
            //     color: '#F89191'
            //   }
            // }
            label: {
              show: true,
              position: 'top',
              color: '#ffffff',
              formatter: '{c}(人)'
            }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 民族分布
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initNationality() {
      let dom = document.getElementById('nationality-map')
      let charts = echarts.init(dom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}人 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          // left: "65%",
          right: '1%',
          bottom: '5%',
          // data: ["汉族", "其它民族"],
          data: this.nationalityX,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        color: ['#ff0000', '#f6c56a', '#42d174', '#4279d1', '#be3acf'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '53%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.nationality
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 学历分布
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initEducation() {
      let dom = document.getElementById('education-map')
      let charts = echarts.init(dom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}人 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          // left: "60%",
          right: '1%',
          bottom: '5%',
          data: this.educationX,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        color: ['#3ab9cf', '#cf493a', '#38a845', '#3840a8'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '53%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.education
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 入党时间
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initTime() {
      let dom = document.getElementById('time-map')
      let charts = echarts.init(dom)
      const option = {
        grid: {
          top: '20%',
          left: '20%',
          bottom: '20%'
          // right: "5%"
        },
        lineStyle: {
          color: '#ffffff',
          width: 1
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: this.timeX,
          axisLabel: {
            interval: 0,
            fontSize: 12,
            color: '#ffffff',
            rotate: '45'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#ffffff'
          }
        },
        series: [
          {
            data: this.time,
            type: 'bar',
            // barWidth: 30,
            // itemStyle: {
            //   normal: {
            //     color: '#FF2929',
            //     barBorderRadius: [15, 15, 15, 15] // （顺时针左上，右上，右下，左下）
            //   }
            // },
            label: {
              show: true,
              position: 'top',
              color: '#ffffff',
              formatter: '{c}(人)'
            }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },
    /**
     * 职业分布
     * @xAxis ->data 数据对应x轴
     * @series ->data 对应数据 对应 y轴
     */
    initProfession() {
      let dom = document.getElementById('profession-map')
      let charts = echarts.init(dom)
      let that = this
      const option = {
        title: {
          text: ''
        },

        tooltip: {
          show: true,
          trigger: 'item',
          confine: true,
          formatter: function (params) {
            let relVal = params.name
            let typeList = that.professionMax
            console.log(JSON.stringify(that.professionMax))
            for (let i = 0; i < params.data.value.length; i++) {
              relVal += '<br/>' + typeList[i]['name'] + ' : ' + params.data.value[i] + ' ' + '人'
            }
            return relVal
          }
        },
        legend: {
          orient: 'vertical',
          left: '80%',
          bottom: '5%',
          textStyle: {
            color: '#fff',
            fontSize: 12
          },
          data: ['']
        },
        radar: {
          // shape: 'circle',
          radius: 45,
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#999',
              borderRadius: 3,
              padding: [3, 5]
            },

            formatter: function (value) {
              let list = value.split('')
              let result = ''
              for (let i = 1; i <= list.length; i++) {
                if (!(i % 4) && list[i] != undefined) {
                  result += list[i - 1] + '\n'
                } else {
                  result += list[i - 1]
                }
              }
              return result
            }
          },
          indicator: this.professionMax,
          splitLine: {
            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
            lineStyle: {
              color: '#fff',
              // 分隔线颜色
              width: 2
              // 分隔线线宽
            }
          }
        },
        series: [
          {
            name: '',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
              {
                value: this.professionValue,
                name: '',
                areaStyle: {
                  // 单项区域填充样式
                  normal: {
                    color: '#FD9797' // 填充的颜色。
                  }
                }
              }
            ]
            // label: {
            //   show: true,
            //   position: "top",
            //   color: "#ffffff",
            //   formatter: '{c}(人)'
            // }
          }
        ]
      }
      if (option && typeof option === 'object') {
        charts.setOption(option, true)
      }
    },

    init() {
      this.POST(API_MODULE.SEARCH_BIG_DATA_STATISTICS)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            // 党员增长
            if (res.data.PARTY_MEMBERSHIP_GRO.length !== 0) {
              this.increaseX = res.data.PARTY_MEMBERSHIP_GRO.map((a) => a.fields1)
              this.increase = res.data.PARTY_MEMBERSHIP_GRO.map((a) => a.fields2)
              this.initIncrease()
            }

            // 党员足迹图
            if (res.data.PARTY_MEMBER_DISTRIB.length === 0) {
              res.data.PARTY_MEMBER_DISTRIB = [{ dataCode: 'NATIONALITY_DISTRIB', fields1: '0', fields2: '0' }]
            }
            // { city: "山东", pepole: 2400 },
            res.data.PARTY_MEMBER_DISTRIB.forEach((element) => {
              const { fields1: city, fields2: pepole } = element
              this.chinaData.push({ city: city, pepole: pepole })
            })
            // this.chinaData = res.data.PARTY_MEMBER_DISTRIB;
            this.maxPepole = Math.max.apply(
              Math,
              this.chinaData.map(function (o) {
                return o.pepole
              })
            )
            this.initChina()

            // 入党时间
            if (res.data.PARTY_MEMBERSHIP_DAT.length !== 0) {
              this.timeX = res.data.PARTY_MEMBERSHIP_DAT.map((a) => a.fields1)
              this.time = res.data.PARTY_MEMBERSHIP_DAT.map((a) => a.fields2)
              this.initTime()
            }

            // 性别情况
            if (res.data.SEX) {
              res.data.SEX.forEach((a) => this.sexData.push({ value: a.fields2, name: a.fields1 }))
              this.initGenderMap()
            }

            // 年龄分布
            if (res.data.AGE_DISTRIB.length !== 0) {
              this.ageX = res.data.AGE_DISTRIB.map((a) => a.fields1)
              this.age = res.data.AGE_DISTRIB.map((a) => a.fields2)
              this.initAge()
            }

            // 民族分布
            if (res.data.NATIONALITY_DISTRIB.length !== 0) {
              this.nationalityX = res.data.NATIONALITY_DISTRIB.map((a) => a.fields1)
              res.data.NATIONALITY_DISTRIB.forEach((element) => {
                this.nationality.push({
                  value: element.fields2,
                  name: element.fields1
                })
              })
              this.initNationality()
            }

            // 学历情况
            if (res.data.EDUCATION_CONDITION.length !== 0) {
              this.educationX = res.data.EDUCATION_CONDITION.map((a) => a.fields1)
              res.data.EDUCATION_CONDITION.forEach((element) => {
                this.education.push({
                  value: element.fields2,
                  name: element.fields1
                })
              })
              this.initEducation()
            }

            // 职业分布
            if (res.data.OCCUPATION_DISTRIB.length !== 0) {
              const max = Math.max.apply(
                Math,
                res.data.OCCUPATION_DISTRIB.map(function (o) {
                  return o.fields2
                })
              )
              res.data.OCCUPATION_DISTRIB.forEach((a) => {
                this.professionMax.push({
                  name: a.fields1,
                  max: max
                })
              })
              this.professionValue = res.data.OCCUPATION_DISTRIB.map((a) => a.fields2)
              this.initProfession()
            }

            // 微党课
            if (res.data.THE_PARTY_CLASS.length !== 0) {
              this.dangKeX = res.data.THE_PARTY_CLASS.map((a) => a.fields1)
              this.dangKe = res.data.THE_PARTY_CLASS.map((a) => a.fields2)
              this.initDangke()
            }
            // 三会一课
            if (res.data.THREE_WILL_BE_LESSON.length !== 0) {
              this.SHYKX = res.data.THREE_WILL_BE_LESSON.map((a) => a.fields1)
              this.SHYK = res.data.THREE_WILL_BE_LESSON.map((a) => a.fields2)
              this.initSanhuiYike()
            }
            // 党员积分
            if (res.data.MEMBERS_INTEGRAL.length !== 0) {
              this.integralList = res.data.MEMBERS_INTEGRAL
            }
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })

      this.POST(API_MODULE.SEARCH_OVERVIEW_STATISTICS)
        .then((res) => {
          if (res.code == 0) {
            this.overviewList = res.data
          } else {
            this.$message.err(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0 0.6rem;
    box-sizing: border-box;
    background-color: #041339;

    .container {
      border-style: solid;
      border-width: 0.05rem;
      @include border_color('border-high');
      border-radius: 0.1rem;
      // padding-top: 0.3rem;
      padding-left: 0.1rem;
      // padding-right: 0.2rem;
      box-sizing: border-box;
      position: relative;
      margin-right: 0.2rem;
      background-color: #05072d;
    }

    .blcok-title {
      text-align: left;

      .block-name {
        font-size: 0.24rem;
        color: #ffffff;
        margin-left: 0.15rem;
      }
    }

    .left-top-icon {
      position: absolute;
      top: -0.05rem;
      left: -0.05rem;
      z-index: 999;
    }

    .left-bottom-icon {
      position: absolute;
      bottom: -0.05rem;
      left: -0.05rem;
      z-index: 999;
    }

    .right-top-icon {
      position: absolute;
      top: -0.05rem;
      right: -0.05rem;
      z-index: 999;
    }

    .right-bottom-icon {
      position: absolute;
      bottom: -0.05rem;
      right: -0.05rem;
      z-index: 999;
    }

    .header {
      display: flex;

      .overview {
        width: 2.5rem;
        height: 7.6rem;

        .context {
          margin-top: 0.3rem;
          width: 100%;
          height: 6.56rem;
          /*overflow-y: scroll;*/

          .zhibu {
            width: 100%;
            height: 1.2rem;
            background: #b10202;
            padding: 0.28rem 0.2rem;
            border-radius: 0.1rem;
            box-sizing: border-box;
            text-align: center;
            color: #ffffff;
            font-size: 0.24rem;
            margin-bottom: 0.4rem;
          }
        }
      }

      .increase {
        width: 7.1rem;
        height: 7.6rem;

        #increase-map {
          width: 6.9rem;
          height: 6.56rem;
        }
      }

      .china {
        width: 7rem;
        height: 7.6rem;

        #china-map {
          width: 6.6rem;
          height: 6.56rem;
        }
      }

      .dangke,
      .sanhui-yike {
        width: 4.2rem;
        height: 3.7rem;

        #dangke-map {
          width: 3.8rem;
          height: 3.3rem;
        }
      }

      .sanhui-yike {
        margin-top: 0.2rem;

        #sanhui-yike-map {
          width: 3.8rem;
          height: 3.3rem;
        }
      }

      .integral {
        width: 2.3rem;
        height: 7.6rem;

        table {
          width: 100%;
          margin-top: 0.2rem;
        }

        th {
          font-size: 0.24rem;
          color: #ffffff;
          padding-bottom: 0.2rem;
        }

        td {
          font-size: 0.2rem;
          color: #ffffff;
          padding-bottom: 0.3rem;
        }
      }

      .dangjian {
        width: 3rem;
        height: 7.6rem;

        .list {
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          // flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          height: 6.56rem;
          margin-top: 0.2rem;
          overflow: hidden;

          .item {
            width: 1.6rem;
            height: 1.3rem;
            @include background_color('background-normal');
            opacity: 1;
            border-radius: 0.1rem;
            margin-bottom: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-number {
              font-size: 0.36rem;
              color: #ffffff;
              font-weight: 600;
            }

            &-name {
              font-size: 0.2rem;
              color: #cca766;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      margin-top: 0.2rem;

      .gender {
        width: 3.9rem;
        height: 4.1rem;

        #gender-map {
          width: 3.5rem;
          height: 3.2rem;
        }
      }

      .age {
        width: 3.9rem;
        height: 4.1rem;

        #age-map {
          width: 3.5rem;
          height: 3.5rem;
        }
      }

      .nationality {
        width: 3.9rem;
        height: 4.1rem;

        #nationality-map {
          width: 3.5rem;
          height: 3.2rem;
        }
      }

      .education {
        width: 3.9rem;
        height: 4.1rem;

        #education-map {
          width: 3.5rem;
          height: 3.2rem;
        }
      }

      .time {
        width: 3.9rem;
        height: 4.1rem;

        #time-map {
          width: 3.5rem;
          height: 3.5rem;
        }
      }

      .profession {
        width: 3.9rem;
        height: 4.1rem;

        #profession-map {
          width: 3.5rem;
          height: 3.2rem;
        }
      }
    }
  }
}
</style>
